/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'

import Layout from '../components/Layout'
import Wrapper from '../components/Wrapper'
import Title from '../components/Title'
import Text from '../components/Text'
import { useIntl } from 'gatsby-plugin-intl'

const TextContainer = styled.div`
  ${({ theme }) => css`
    margin: 50px auto;
    width: 75%;

    ${theme.queries.small} {
      margin: 50px 0;
      width: 100%;
      padding: 0 10px;
    }
  `}
`

const SimpleText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.arsenic};
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px !important;
  `}
`

const Privacidade = () => {
  const intl = useIntl()

  return (
    <Layout>
      <TextContainer>
        <Wrapper>
          <TextContainer>
            <Title
              align="center"
              size="40"
              css={css`
                margin-bottom: 40px;
              `}
            >
              {intl.formatMessage({ id: 'pages.terms.title' })}
            </Title>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.last_update' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_1' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_2' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_3' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_4' })}
            </SimpleText>
            <SimpleText weight="500">
              {intl.formatMessage({ id: 'pages.terms.text_5' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_6' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_7' })}
            </SimpleText>
            <SimpleText weight="500">
              {intl.formatMessage({ id: 'pages.terms.text_8' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_9' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_10' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_11' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_12' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_13' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_14' })}
            </SimpleText>

            <SimpleText weight="500">
              {intl.formatMessage({ id: 'pages.terms.text_15' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_16' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_17' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_18' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_19' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_20' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_21' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_22' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_23' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_24' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_25' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_26' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_27' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_28' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_29' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_30' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_31' })}
            </SimpleText>

            <SimpleText weight="500">
              {intl.formatMessage({ id: 'pages.terms.text_32' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_33' })}
            </SimpleText>

            <SimpleText weight="500">
              {intl.formatMessage({ id: 'pages.terms.text_34' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_35' })}
            </SimpleText>

            <SimpleText weight="500">
              {intl.formatMessage({ id: 'pages.terms.text_36' })}
            </SimpleText>

            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_37' })}
            </SimpleText>

            <SimpleText weight="500">
              {intl.formatMessage({ id: 'pages.terms.text_38' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_39' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_40' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_41' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_42' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_43' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_44' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_45' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_46' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_47' })}
            </SimpleText>
            <SimpleText>
              {intl.formatMessage({ id: 'pages.terms.text_48' })}
            </SimpleText>
          </TextContainer>
        </Wrapper>
      </TextContainer>
    </Layout>
  )
}

export default Privacidade
